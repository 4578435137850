<template>
    <div class="post">
        <div class="flex" v-if="notice.notifications.comment" @click="get_post()"
            :class="[notice.notification_seen == '1' ? 'seen' : '']">
            <div class="dp">
                <img :src="notice.notifications.comment.dp"/>
            </div>
            <div>
                <span class="name">
                    {{ notice.notifications.comment.firstname }} {{ notice.notifications.comment.lastname }}
                </span>
                <span class="text">
                    <span v-if="notice.notifications.comment.count > 0">
                        and {{ notice.notifications.comment.count }} others
                    </span>
                    commented on your post · {{ commentdate }}
                </span>
            </div>

            <div v-if="loading">
                <div class="spin"></div>
            </div>
            <div class="icon" v-else @click.stop="togglepop('show_opt')">
                <optionicon :size="'18px'" :style="{'height': '18px'}"/>
            </div>
        </div>
        <div class="flex" v-if="notice.notifications.reaction" @click="get_post()"
            :class="[notice.notification_seen == '1' ? 'seen' : '']">
            <div class="dp">
                <img :src="notice.notifications.reaction.dp"/>
            </div>
            <div>
                <span class="name">
                    {{ notice.notifications.reaction.firstname }} {{ notice.notifications.reaction.lastname }}
                </span>
                <span class="text">
                    <span v-if="notice.notifications.reaction.count > 0">
                        and {{ notice.notifications.reaction.count }} others
                    </span>
                    reacted on your post · {{ reactiondate }}
                </span>
            </div>
            
            <div v-if="!notice.notifications.comment">
                <div v-if="loading">
                    <div class="spin"></div>
                </div>
                <div v-else>
                    <div class="icon" @click.stop="togglepop('show_opt')">
                        <optionicon :size="'18px'" :style="{'height': '18px'}"/>
                    </div>
                </div>
            </div>
        </div>
        
        
        <div class="popup" v-if="pop">
            <div class="dark" @click="closepop()"></div>

            <transition name="slide">
                <noti_options v-if="show_opt" :notice="notice" :stop="true"
                    @marked="marked()" @stopped="stopped()" @removed="remove()"/>
            </transition>
        </div>
    </div>
</template>

<script>
import moment from 'moment';

import optionicon from '../../icons/option.vue';
import noti_options from './options.vue'

export default {
    components: {
        optionicon, 
        noti_options
    },
    props: ['notice'],

    data() {
        return {
            commentdate: '',
            reactiondate: '',

            pop: false,
            show_opt: false,
            
            loading: false
        }
    },
    created() {
        const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {
            if (this.pop) {
                this.closepop()
                next(false)
            } else {
                next()
            }
        })

        this.$once('hook:destroyed', () => {
            unregisterRouterGuard()
        })
    },
    mounted() {
        if (this.notice.notifications.comment) {
            this.commentdate = moment(this.notice.notifications.comment.date).fromNow()
        }
        if (this.notice.notifications.reaction) {
            this.reactiondate = moment(this.notice.notifications.reaction.date).fromNow()
        }
    },
    methods: {
        
        get_post() {
            this.loading = true

            let form = new FormData()
            form.append('post_id', this.notice.id)

            this.$http.post('/post/one', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    let post = r.post[0]
                    localStorage.setItem('post', JSON.stringify(post))
                    this.$router.push('/post/' + post.id)
                }
                this.loading = false
            })
            
            if (this.notice.notification_seen == '0') {
                let form2 = new FormData()
                form2.append('id', this.notice.id)
                form2.append('notification_type', this.notice.notification_type)

                this.$http.post('/notification/mark', form2).then(() => {
                    this.marked()
                })
            }
        },
        togglepop(what) {
            this.pop = true
            setTimeout(() => {
                this[what] = true
            }, 100);
        },
        closepop() {
            this.show_opt = false
            setTimeout(() => {
                this.pop = false
            }, 200);
        },

        marked() {
            this.closepop()
            this.notice.notification_seen = '1'
        },
        stopped() {
            this.closepop()
            this.notice.notification_on = '0'
        },
        remove() {
            this.closepop()

            setTimeout(() => {
                this.$emit('removed')
            }, 210);
        }
    }
}
</script>

<style scoped>
    .post {
        margin-bottom: 10px;
    }
    .flex {
        display: grid;
        grid-template-columns: 50px auto 32px;
        align-items: center;
        gap: 15px;
        padding: 7px 15px;
        background-color: var(--trans);
    }
    .seen {
        background-color: white;
    }
    .dp {
        width: 50px;
        height: 50px;
        overflow: hidden;
        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;
    }
    .dp img {
        width: 50px;
    }

    .name {
        font-weight: 700;
        font-size: 14px;
    }
    .text {
        color: gray;
        font-size: 14px;
    }
    .icon {
        margin-left: auto;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        border: 1px solid var(--trans);
        fill: var(--main);
    }
    .spin {
        width: 24px;
        height: 24px;
    }
    


    .popup {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-end;
        z-index: 3;
    }
    .dark {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }
    .slide-enter-active {
        animation: move .3s;
    }
    .slide-leave-active {
        animation: .2s move reverse;
    }
    @keyframes move {
        from {
            margin-bottom: -50vh;
        }
        to {
            margin-bottom: 0px;
        }
    }
</style>