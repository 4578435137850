<template>
    <div class="options">
        <div class="title">Notifications Options</div>
        <div class="main">
            <div class="item" @click="mark()" v-if="notice.notification_seen == '0'">
                <markicon :size="'20px'"/>
                <div>
                    <div class="t">Mark Notification</div>
                    <div class="label">Mark this notification as read</div>
                </div>
            </div>
            <div v-if="stop">
                <div class="item" @click="stop_noty()" v-if="notice.notification_on == '1'">
                    <remove :size="'20px'"/>
                    <div>
                        <div class="t">Stop Notification</div>
                        <div class="label">
                            Stop seeing notifications from this {{ notice.notification_type }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="item" @click="remove()">
                <deleteicon :size="'20px'"/>
                <div>
                    <div class="t">Delete Notification</div>
                    <div class="label">Remove this notification</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import markicon from '../../icons/mark.vue'
import remove from '../../icons/remove.vue'
import deleteicon from '../../icons/delete.vue';
export default {
    components: {
        markicon, remove, deleteicon
    },
    props: ['notice', 'stop'],

    methods: {
        mark() {
            let form = new FormData()
            form.append('id', this.notice.id)
            form.append('notification_type', this.notice.notification_type)

            this.$http.post('/notification/mark', form)
            this.$emit('marked')
        },
        stop_noty() {
            let form = new FormData()
            form.append('id', this.notice.id)
            form.append('notification_type', this.notice.notification_type)

            this.$http.post('/notification/stop', form)
            this.$emit('stopped')
        },
        remove() {
            let form = new FormData()
            form.append('id', this.notice.id)
            form.append('notification_type', this.notice.notification_type)

            this.$http.post('/notification/delete', form)
            this.$emit('removed')
        }
    }
}
</script>

<style scoped>
    
    .options {
        background-color: white;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        width: 100%;
        position: relative;
        z-index: 2;
        transition: .2s;
    }
    .title {
        padding: 20px;
        border-bottom: 1px solid #ddd;
        font-weight: 500;
    }
    .main {
        max-height: 50vh;
        padding: 10px 0px;
        overflow: auto;
    }
    .item {
        display: grid;
        grid-template-columns: max-content auto max-content;
        align-items: center;
        gap: 20px;
        fill: #555;
        padding: 10px 20px;
    }
    .t {
        font-weight: 500;
        font-size: 14px;
    }
    .label {
        font-size: 12px;
        font-weight: 500;
        color: grey;
    }
    
    .spin {
        width: 20px;
        height: 20px;
        border-width: 2px;
    }
</style>