<template>
    <div class="follow" :class="[notice.notification_seen == '1' ? 'seen' : '']" @click="get_user()">
        <div class="dp">
            <img :src="notice.notifications.dp"/>
        </div>
        <div>
            <span class="name">
                {{ notice.notifications.firstname }} {{ notice.notifications.lastname }}
            </span>
            <span class="text">
                started following you · {{ date }}
            </span>
        </div>
        
        <div v-if="loading">
            <div class="spin"></div>
        </div>
        <div v-else class="icon" @click.stop="togglepop('show_opt')">
            <optionicon :size="'18px'" :style="{'height': '18px'}"/>
        </div>

        
        <div class="popup" v-if="pop">
            <div class="dark" @click="closepop()"></div>

            <transition name="slide">
                <noti_options v-if="show_opt" :notice="notice" 
                    @marked="marked()" @removed="remove()"/>
            </transition>
        </div>
    </div>
</template>

<script>
import moment from 'moment';

import optionicon from '../../icons/option.vue';

import noti_options from './options.vue'

export default {
    components: {
        optionicon,
        noti_options
    },
    props: ['notice'],
    data() {
        return {
            date: '',

            pop: false,
            show_opt: false,
            
            loading: false
        }
    },
    created() {
        const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {
            if (this.pop) {
                this.closepop()
                next(false)
            } else {
                next()
            }
        })

        this.$once('hook:destroyed', () => {
            unregisterRouterGuard()
        })
    },
    mounted() {
        this.date = moment(this.notice.notification_date).fromNow()
    },
    methods: {
        get_user() {
            this.loading = true

            let form = new FormData()
            form.append('username', this.notice.notifications.username)
            this.$http.post('/profile', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    r.user['noww'] = true
                    localStorage.setItem('profile', JSON.stringify(r.user))
                            
                    setTimeout(() => {
                        this.$router.push('/profile/' + this.notice.notifications.username)
                    }, 200);
                }
                this.loading = false
            })
            
            if (this.notice.notification_seen == '0') {
                let form2 = new FormData()
                form2.append('id', this.notice.id)
                form2.append('notification_type', this.notice.notification_type)

                this.$http.post('/notification/mark', form2).then(() => {
                    this.marked()
                })
            }
        },
        togglepop(what) {
            this.pop = true
            setTimeout(() => {
                this[what] = true
            }, 100);
        },
        closepop() {
            this.show_opt = false
            setTimeout(() => {
                this.pop = false
            }, 200);
        },

        marked() {
            this.closepop()
            this.notice.notification_seen = '1'
        },
        remove() {
            this.closepop()

            setTimeout(() => {
                this.$emit('removed')
            }, 210);
        }
    }
}
</script>

<style scoped>
    .follow {
        display: grid;
        grid-template-columns: 50px auto 32px;
        align-items: center;
        gap: 15px;
        padding: 7px 15px;
        background-color: var(--trans);
        margin-bottom: 10px;
    }
    .seen {
        background-color: white;
    }
    .dp {
        width: 50px;
        height: 50px;
        overflow: hidden;
        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;
    }
    .dp img {
        width: 50px;
    }

    .name {
        font-weight: 700;
        font-size: 14px;
    }
    .text {
        color: gray;
        font-size: 14px;
    }

    .icon {
        margin-left: auto;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        border: 1px solid var(--trans);
        fill: var(--main);
    }
    .spin {
        width: 24px;
        height: 24px;
    }

    
    .popup {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-end;
        z-index: 3;
    }
    .dark {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }
    .slide-enter-active {
        animation: move .3s;
    }
    .slide-leave-active {
        animation: .2s move reverse;
    }
    @keyframes move {
        from {
            margin-bottom: -50vh;
        }
        to {
            margin-bottom: 0px;
        }
    }
</style>