<template>
    <div class="notifications" 
        ref="container" @touchstart="swipeStart($event)" @touchend="swipeEnd($event)">

        <div class="head">
            <div class="back" @click="back()">
                <back :size="'15px'"/>
            </div>
            <div class="title">Notifications</div>
        </div>

        <div class="main">
            
            <div class="l" v-if="loading">
                <loader/>
            </div>

            <div class="empty" v-if="notifications.length == 0 && !loading">
                <notificationicon :size="'30px'"/>
                You don't have any notifications at the moment
            </div>

            <div v-for="(notice, i) in notifications" :key="i">
                <follow :notice="notice" v-if="notice.notification_type == 'follow'"
                    @removed="removed(i)"/>

                <post :notice="notice" v-if="notice.notification_type == 'post'"
                    @removed="removed(i)"/>
                <comment :notice="notice" v-if="notice.notification_type == 'comment'"
                    @removed="removed(i)"/>
            </div>
        </div>

        <!-- <menulayout/> -->
    </div>
</template>

<script>
import pull_to_refresh from '../mixins/pull_to_refresh.js'


import back from '../icons/back.vue';

import loader from '../components/loader.vue'
import notificationicon from '../icons/notification.vue';

import follow from '../components/notification/follow.vue';
import post from '../components/notification/post.vue'
import comment from '../components/notification/comment.vue'

// import menulayout from '../components/menu.vue';
export default {
    components: {
        back,
        loader,
        notificationicon,
        follow, post, comment,
        // menulayout
    },
    mixins: [pull_to_refresh],
    data() {
        return {
            notifications: [],
            loading: true
        }
    },
    mounted() {
        this.get_notifications()
    },
    methods: {
        back() {
            this.$router.go(-1)
        },
        refresh() {
            this.get_notifications()
        },
        get_notifications() {
            this.loading = true
            this.$http.post('/user/notifications')
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.notifications = r.notifications

                    this.loading = false
                }
            })
        },
        removed(i) {
            this.notifications.splice(i, 1)
        }
    },

}
</script>

<style scoped>
    .notifications {
        background-color: white;
    }
    .edit {
        height: 100vh;
        width: 100%;
    }
    .head {
        display: grid;
        /* grid-template-columns: calc(50% - 40px) 50%; */
        align-items: center;
        padding: 15px 15px;
        border-bottom: 1px solid #ddd;
        fill: var(--main);

        position: relative;
    }
    .back {
        position: absolute;
        top: 0px;
        left: 0px;

        width: 50px;
        height: 50px;

        display: flex;
        align-items: center;
        justify-content: center;
        transition: .3s;
    }
    .back:active {
        background-color: #edeef0;
    }
    .title {
        font-weight: 500;
        text-align: center;
    }

    .l {
        padding: 50px 0px;
    }
    .empty {
        padding: 50px 20px;
        text-align: center;
        font-size: 12px;
        color: gray;
        fill: #555;
    }
    .main {
        height: calc(100vh - 50px);
        overflow: auto;
        /* background-color: #edeff0; */
    }
</style>